(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/article/assets/article-reducer.js') >= 0) return;  svs.modules.push('/components/tipsen/article/assets/article-reducer.js');
"use strict";

var _svs$tipsen, _svs$tipsen2;
const {
  createReducer
} = RTK;
const {
  fetchArticle
} = svs.components.tipsen.article.actions;
const {
  REDUCER_STATE_INIT,
  REDUCER_STATE_FETCHING,
  REDUCER_STATE_REJECTED,
  REDUCER_STATE_DONE
} = svs.components.tipsen.constants;
const initialState = {};
const spelguide = (_svs$tipsen = svs.tipsen) === null || _svs$tipsen === void 0 || (_svs$tipsen = _svs$tipsen.data) === null || _svs$tipsen === void 0 ? void 0 : _svs$tipsen.spelguide;
const spelguideArticleId = (_svs$tipsen2 = svs.tipsen) === null || _svs$tipsen2 === void 0 || (_svs$tipsen2 = _svs$tipsen2.data) === null || _svs$tipsen2 === void 0 || (_svs$tipsen2 = _svs$tipsen2.spelguideConfig) === null || _svs$tipsen2 === void 0 || (_svs$tipsen2 = _svs$tipsen2.spelguide) === null || _svs$tipsen2 === void 0 ? void 0 : _svs$tipsen2.article;
if (spelguideArticleId) {
  initialState[spelguideArticleId] = {
    article: spelguide,
    state: spelguide ? REDUCER_STATE_DONE : REDUCER_STATE_INIT
  };
}
const getArticle = (state, articleId) => {
  if (!state[articleId]) {
    state[articleId] = {
      article: undefined,
      state: REDUCER_STATE_INIT
    };
  }
  return state[articleId];
};
const articlesReducer = createReducer(initialState, builder => {
  builder.addCase(fetchArticle.pending, (state, action) => {
    const {
      articleId
    } = action.meta.arg;
    const article = getArticle(state, articleId);
    article.state = REDUCER_STATE_FETCHING;
  });
  builder.addCase(fetchArticle.fulfilled, (state, action) => {
    const {
      articleId
    } = action.meta.arg;
    const article = getArticle(state, articleId);
    article.article = action.payload;
    article.state = REDUCER_STATE_DONE;
  });
  builder.addCase(fetchArticle.rejected, (state, action) => {
    const {
      articleId
    } = action.meta.arg;
    const article = getArticle(state, articleId);
    article.state = REDUCER_STATE_REJECTED;
  });
});
setGlobal('svs.components.tipsen.article.articleReducer', articlesReducer);

 })(window);