(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/article/assets/article-selectors.js') >= 0) return;  svs.modules.push('/components/tipsen/article/assets/article-selectors.js');
"use strict";

const {
  REDUCER_STATE_INIT,
  REDUCER_STATE_DONE,
  REDUCER_STATE_REJECTED
} = svs.components.tipsen.constants;
const selectArticleIsDone = (state, articleId) => {
  const articleState = state.Articles && state.Articles[articleId] ? state.Articles[articleId].state : REDUCER_STATE_INIT;
  return articleState === REDUCER_STATE_DONE;
};
const selectArticleIsRejected = (state, articleId) => {
  const articleState = state.Articles && state.Articles[articleId] ? state.Articles[articleId].state : REDUCER_STATE_INIT;
  return articleState === REDUCER_STATE_REJECTED;
};
const selectArticleShouldShowSkeleton = (state, articleId) => !selectArticleIsDone(state, articleId) && !selectArticleIsRejected(state, articleId);
const selectArticle = (state, cacheKey) => {
  const article = state.Articles && state.Articles[cacheKey] ? state.Articles[cacheKey].article : undefined;
  return article;
};
setGlobal('svs.components.tipsen.article.selectors', {
  selectArticleIsDone,
  selectArticleIsRejected,
  selectArticleShouldShowSkeleton,
  selectArticle
});

 })(window);