(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/article/assets/article-actions.js') >= 0) return;  svs.modules.push('/components/tipsen/article/assets/article-actions.js');
"use strict";

const {
  loadArticle
} = svs.content.simple_article.contentLoader;
const {
  ContentUrlBuilder
} = svs.content.shared;
const {
  createAsyncThunk
} = RTK;
const fetchArticle = createAsyncThunk('tipsen/fetchArticle', (_ref, _ref2) => {
  let {
    articleId,
    forceFetch = false
  } = _ref;
  let {
    getState
  } = _ref2;
  if (!articleId) {
    throw new Error('Missing argument. articleId required');
  }
  const options = {
    url: new ContentUrlBuilder("/content/2/id/".concat(articleId)).build()
  };
  const state = getState();
  return new Promise((resolve, reject) => {
    const article = !forceFetch ? state.Articles[articleId].article : undefined;
    if (article) {
      resolve(article);
      return;
    }
    loadArticle(options, (err, article) => {
      if (err) {
        reject(err);
        return;
      }
      resolve(article);
    });
  });
});
setGlobal('svs.components.tipsen.article.actions', {
  fetchArticle
});

 })(window);